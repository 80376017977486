/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
import { FirstName, LastName } from "../../utils/getName";

import "./About.css";

import profile from "../../assets/profile.png";

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: "100vw",
    marginTop: "3em",
    marginBottom: "auto",
  },
}));

export const About = () => {
  const classes = useStyles();
  const greetings = "Hello there!";
  const aboutme = `I'm a 21 year-old, South African, Frontend and Blockchain Engineer with a strong passion for developing websites with great user experiences. I currently work as a Software and Support Engineer at a Telecommunications company. I studied Mechanical Engineering and Computer Engineering at the University of Kwa-Zulu-Natal but dropped out of Computer Engineering to persue my passion in programming and fell in love with Web Dev. My goal as a Frontend Engineer is to create high performant, interactive websites that are extremely unique`;

  return (
    <section id="about">
      <Container component="main" className={classes.main} maxWidth="md">
        <div className="about">
          <div
            className="_img"
            style={{
              background: "url(" + profile + ")",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="_content_wrapper">
            <Typography component="h2" variant="h5">
              <TextDecrypt text={`${greetings}`} />
            </Typography>
            <p className="aboutme">{aboutme}</p>
            <a href="#contact" className="contact-btn">
              <i className="fas fa-terminal"></i>
              <Typography component="span"> Send me a message.</Typography>
            </a>
          </div>
        </div>
        <div className="modal__languages">
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="	https://cdn-icons-png.flaticon.com/512/1051/1051277.png"
              alt=""
            ></img>
            <span className="language__name react">HTML5</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="https://cdn-icons-png.flaticon.com/512/732/732190.png"
              alt=""
            ></img>
            <span className="language__name react">CSS3</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="https://cdn-icons-png.flaticon.com/512/5968/5968292.png"
              alt=""
            ></img>
            <span className="language__name react">JavaScript</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="https://cdn.iconscout.com/icon/free/png-256/react-3-1175109.png"
              alt=""
            ></img>
            <span className="language__name react">React</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="	https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg"
              alt=""
            ></img>
            <span className="language__name react">Tailwind</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="https://cdn-icons-png.flaticon.com/512/5968/5968350.png"
              alt=""
            ></img>
            <span className="language__name react">Python</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="	https://www.vectorlogo.zone/logos/nodejs/nodejs-icon.svg"
              alt=""
            ></img>
            <span className="language__name react">NodeJS</span>
          </figure>
          <figure className="modal__language">
            <img
              className="modal__language--img"
              src="https://cdn-icons-png.flaticon.com/128/4492/4492311.png"
              alt=""
            ></img>
            <span className="language__name react">SQL</span>
          </figure>
        </div>
      </Container>
    </section>
  );
};
