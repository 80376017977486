/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "../content/TextDecrypt";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./Works.css";

// Import ../../assets/recentprojects/
import ReactDashboard from "../../assets/recentprojects/react_dashboard_image.png";
import YouTube from "../../assets/recentprojects/YouTube_clone.png";
import MovieHub from "../../assets/recentprojects/MovieHub.png";
import ThreeJS from "../../assets/recentprojects/ThreeJS Portfolio.png";

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: "100vw",
    marginTop: "3em",
    marginBottom: "auto",
  },
}));

export const Works = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState([
    // {
    //   id: 1,
    //   title: "test",
    //   description: `Designed and developed a ReactJS portfolio 
    //   with fancy 3D animations using Three.js for 
    //   the background element.`,
    //   alter: "React Portfolio",
    //   image: `${Portfolio}`,
    // },
    // {
    //   id: 2,
    //   title: "React Dashboard",
    //   description: `Built a React dashboard for an e-commerce company using Syncfusion React components.`,
    //   alter: "React Dashboard",
    //   image: `${Veritru}`,
    //   linkG: "https://github.com/hashkader/React-Dashboard",
    //   linkL: "https://react-dashboard-seven-blush.vercel.app/ecommerce",
    // },
    // {
    //   id: 3,
    //   title: "LoFo Project",
    //   description: `Logistics and Forwarding website built using
    //   ReactJS to design and develop its front-end.`,
    //   alter: "LoFo Project",
    //   image: `${Lofo}`,
    // },
    // {
    //   id: 4,
    //   title: "Startup Project",
    //   description: `A website portfolio project for the Startup Dev Team
    //   built using MEVN stack to demonstrate the CRUD capabilities of the tech stack.`,
    //   alter: "Startup Project",
    //   image: `${Startup}`,
    // },
    // {
    //   id: 5,
    //   title: "LaCalle Cafe",
    //   description: `A website project for the La Calle Cafe business
    //   built using Wordpress and PHP with integrated SEO tools to help
    //   the business ramp up its prospects and lead generation.`,
    //   alter: "Startup Project",
    //   image: `${Lacalle}`,
    // },
  ]);

  return (
    // <section id="works">
    //   <Container component="main" className={classes.main} maxWidth="md">
    //     {projects.map((project) => (
    //       <div className="project" key={ project.id }>
    //         <div className="__img_wrapper">
    //           <img src={ project.image } alt={ project.alter }/>
    //         </div>
    //         <div className="__content_wrapper">
    //           <h3 className="title">
    //             <TextDecrypt text={ project.id + '. ' + project.title } />
    //           </h3>
    //           <p className="description">
    //             { project.description }
    //           </p>

    //           <div>
    //           <a className="description icon" href={project.linkG} target="_blank">
    //           <i class="fab fa-github"></i>
    //           </a>
    //           <a className="description" href={project.linkL} target="_blank">
    //           <i class="fas fa-link"></i>
    //           </a>
    //           </div>

    //         </div>

    //       </div>
    //     ))}
    //   </Container>
    // </section>
    <section id="works">
      <figure className="snip1543">
        <img src= {ReactDashboard}/>
        <figcaption>
          <h3>React Dashboard</h3>
          <div className="description"><p>
           React Admin Dashboard App With Theming, Tables, Charts, Calendar, Kanban and More. The core tech stack for this
           project is React and I've used Syncfusion React Components for my charts and tables.
           Click on the project to access the live site. 
          </p></div>
          
        </figcaption>
        <a href="https://react-dashboard-seven-blush.vercel.app/ecommerce" target= "_blank"></a>
      </figure>

      <figure className="snip1543">
        <img
          src= {YouTube}
        
        />
        <figcaption>
          <h3>YouTube Clone</h3>
          <div className="description"><p>
            Developed a fully functional, upgraded version of YouTube using React and a Youtube API.
            Click on the project to access the live site. 
          </p></div>
          
        </figcaption>
        <a href="https://you-tube-clone-v2-ejyxw32e4-hashkader.vercel.app/" target= "_blank"></a>
      </figure>
      
      <figure className="snip1543">
        <img
          src= {MovieHub}
        
        />
        <figcaption>
          <h3>MovieHub</h3>
          <div className="description"><p>
            A Movie website utilizes the IMDB API to display movies, their ratings and actors and much more.
            This site uses Artifical Intelligence to swiftly navigate to a different page or change your current contrast settings.
            Simply click the mic icon on the bottom right of the screen and say "What does this App do" to receive a list of commands.
            Click on the project to access the live site.
          </p></div>
          
        </figcaption>
        <a href="https://moviehub2.netlify.app/" target= "_blank"></a>
      </figure>

      <figure className="snip1543">
        <img
          src= {ThreeJS}
        
        />
        <figcaption>
          <h3>ThreeJS Portfolio</h3>
          <div className="description"><p>
            Developed a ThreeJS Portfolio for myself. Decided against using it to apply for jobs as I found it not to be professional enough.
            This site was created using ThreeJS and WebGL. I used blender to render the 3D image.
          </p></div>
          
        </figcaption>
        <a href="https://hashimkaderthreejsportfolio.netlify.app/" target= "_blank"></a>
      </figure>
      
     
    </section>
  );
};
